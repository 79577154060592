<template>
  <q-form class="q-gutter-sm q-mt-none" @submit="handleConnectCredentials">
    <slot />
    <!-- ReCaptcha -->
    <form-general-error error-type="recaptcha_result" class="q-my-md" />

    <p :class="$slots.default ? 'q-mt-lg' : 'q-mt-none'">
      {{ $t('account.signIn.recaptchaTitle') }}
    </p>
    <component
      :is="GoogleReCaptacha"
      v-if="recaptchaKey"
      ref="recaptchaRef"
      v-model:error="error"
      :model-value="modelValue"
      :recaptcha-key="recaptchaKey"
      @update:model-value="emits('update:modelValue', $event)"
    />
    <slot name="actions">
      <!-- Connect btn -->
      <div class="flex justify-end q-mt-lg">
        <q-btn
          type="submit"
          :loading="loading"
          class="q-mt-lg btn-lg btn-wide"
          color="primary"
          :label="btnLabel"
        />
      </div>
    </slot>
  </q-form>
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent, onBeforeMount, ref } from 'vue'
import { i18n } from 'boot/common/i18n'

import FormGeneralError from 'components/common/ui/form/FormGeneralError.vue'

// PROPS
const props = withDefaults(
  defineProps<{
    modelValue: string
    submitFct: () => Promise<boolean>
    btnLabel?: string
  }>(),
  {
    btnLabel: () => i18n.global.t('common.create'),
  }
)
// EMITS
const emits = defineEmits<{
  'update:modelValue': [code: string]
}>()

const recaptchaRef = ref()

// Get the recaptcha key
const recaptchaKey = ref<string | null>(null)

onBeforeMount(async () => {
  recaptchaKey.value = await getRecaptchaKey()
})

const getRecaptchaKey = async () => {
  if (process.env.WT_ENV === 'sender') {
    const config = await import('stores/sender/config')
    return config.useConfigStore().recaptcha_key
  } else {
    const config = await import('stores/receiver/config')
    return config.useConfigStore().recaptcha_key
  }
}

// Import the mocked component if we are not in Prod
const GoogleReCaptacha = computed(() => {
  if (process.env.PROD) {
    return defineAsyncComponent(
      () => import('components/common/ui/form/recaptcha/GoogleReCaptacha.vue')
    )
  } else {
    return defineAsyncComponent(
      () => import('components/common/ui/form/recaptcha/DevRecaptcha.vue')
    )
  }
})

const error = ref(false)

const loading = ref(false)

const handleConnectCredentials = async () => {
  if (props.modelValue) {
    loading.value = true

    const success = await props.submitFct()

    if (!success) {
      if (recaptchaRef.value?.reset) {
        recaptchaRef.value?.reset()
      }
      emits('update:modelValue', '')
    }

    loading.value = false
  } else {
    error.value = true
  }
}
</script>
